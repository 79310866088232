/* .wp-block-columns {
  &.is-reversed-direction-on-mobile {
    @media (max-width: 781px) {
      flex-direction: column-reverse;
    }

    &.is-not-stacked-on-mobile {
      @media (max-width: 781px) {
        flex-direction: row-reverse;
      }
    }
  }
} */

/* undo wordpress breakpoints */
/* Change Columns breakpoint to 1024px */
@media (max-width: 1023px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) {
    flex-wrap: wrap !important;
  }

  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 100% !important;
  }
}

@media (min-width: theme(screens.lg)) {
  /* .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
  } */
}
